// quote.js
export default {
  data() {
    return {
      dropdownOptions: [
        "30 Days",
        "45 Days",
        "60 Days",
        "90 Days",
        "Custom Date",
      ],
      otherTermsOptions: [
        {
          text: "100% advance payment",
          value: "100% advance payment",
        },
        {
          text: "Payment after delivery",
          value: "payment after delivery",
        },
        {
          text: "50% Advance and balance before delivery",
          value: "50% Advance and balance before delivery",
        },
        {
          text: "Others (custom)",
          value: "others",
        },
      ],
      vatOption: [
        {
          text: "Add VAT",
          value: "true",
        },
        {
          text: "No VAT",
          value: "false",
        },
      ],
      statusOption: [
        {
          text: "Open",
          value: "open",
        },
        {
          text: "Sent",
          value: "sent",
        },
        {
          text: "Review",
          value: "review",
        },
        {
          text: "Accepted",
          value: "accepted",
        },
        {
          text: "Rejected",
          value: "rejected",
        },
      ],
      currencyItems: ["NGN", "USD", "GBP", "EUR"],
      quoteDetails: {
        label: "",
        currency: "",
        dateOfIssue: null,
        priceValidity: null,
        attention: "",
        deliveryTerms: "",
        vat: "true",
        otherterms: "",
        status: "open",

        rfq: "",
        counterparty: {
          id: "",
          company: "",
          entity_type: "",
        },
        lineItems: [
          {
            description: "",
            qty: null,
            uom: "",
            unit_price: null,
            total_price: null,
            oem_price: null,
            lead_time: "",
            material_no: "",
          },
        ],
      },
      itemStyles: [
        {
          label: "Description",
          key: "description",
          width: this.$route.query.delivery_note ? "350px" : "250px",
          minWidth: "200px",
          placeholder: "Description",
          hasSwitch: false,
          value: "",
          switchValue: false,
        },
        {
          label: "QTY",
          key: "qty",
          width: this.$route.query.delivery_note ? "150px" : "70px",
          minWidth: "70px",
          placeholder: "Qty",
          hasSwitch: false,
          value: "",
          switchValue: false,
        },
        {
          label: "UOM",
          key: "uom",
          width: "70px",
          minWidth: "70px",
          placeholder: "uom",
          hasSwitch: false,
          value: "",
          switchValue: false,
        },
        {
          label: "Unit Price",
          key: "unit_price",
          width: "100px",
          minWidth: "100px",
          placeholder: "0.00",
          hasSwitch: false,
          value: "",
          switchValue: false,
        },
        {
          label: "Total Price",
          key: "total_price",
          width: "100px",
          minWidth: "100px",
          placeholder: "0.00",
          hasSwitch: false,
          value: "",
          switchValue: false,
        },
        {
          label: "OEM price",
          key: "oem_price",
          width: "140px",
          minWidth: "140px",
          placeholder: "0.00",
          hasSwitch: true,
          value: "",
          switchValue: false,
        },
        {
          label: "Lead Time",
          key: "lead_time",
          width: "140px",
          minWidth: "140px",
          placeholder: "Lead Time",
          hasSwitch: true,
          value: "",
          switchValue: false,
        },
        {
          label: "Material No.",
          key: "material_no",
          width: "140px",
          minWidth: "140px",
          placeholder: "#",
          hasSwitch: true,
          value: "",
          switchValue: false,
        },
      ],
    };
  },

  methods: {
    // create a method that takes a date string like YYYY-MM-DD as params and calculate number of days from current date

    calculateDays(date, oldDate) {
      const issuedDate = new Date(date);
      const createdDate = new Date(oldDate);
      const timeDiff = Math.abs(createdDate.getTime() - issuedDate.getTime());
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return diffDays;
    },

    //    fetch customer details using id
    async getCustomerDetails(id) {
      const { data } = await this.$store.dispatch(
        "contacts/getSingleCustomer",
        id
      );

      return {
        id: data.customer.hypn_id,
        company: data.customer.company.company_name,
        entity_type: "customer",
      };
    },
  },
};
